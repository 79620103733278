import { memo } from 'react'

import { Text } from '@cash-me/react-components'

import { Help as HelpComp } from 'modules/help'

import { accordion } from './data'

const Help = memo(() => (
  <HelpComp accordion={accordion}>
    <Text className="subtitle" size="caption" weight="medium" uppercase>
      Ajuda
    </Text>
    <Text as="h2" size="heading3" weight="medium">
      <Text as="span" size="heading3" weight="medium" color="var(--colors-pink-text-default)">
        Tire as suas <br />
        dúvidas
      </Text>{' '}
      sobre <br />
      empréstimo para <br />
      condomínios
    </Text>

    <Text className="description" color="var(--colors-neutral-text-weak)">
      Confira as perguntas mais frequentes.
    </Text>
  </HelpComp>
))

Help.displayName = 'Help'

export default Help
